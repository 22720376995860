<template>
  <div>
    <v-app-bar dense elevation="0" color="transparent">
      <v-btn text @click="$router.push({ path: '/admin' })">
        <v-icon color="grey">mdi-home</v-icon>
        首頁
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="logout()"> 登出 </v-btn>
    </v-app-bar>
    <div class="mx-10 my-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AdminContainer',
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    ...mapActions({
      logoutAction: 'user/logout'
    }),
    logout() {
      this.logoutAction()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped></style>
